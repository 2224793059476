import "./index.scss";

function Home() {
    return (
        <div>

            {/* navbar */}
            <div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary" >
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <img src="assets/images/Ever Coded Logo White.svg" style={{ width: "50%" }} />
                        </a>
                        <form className="d-flex">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                                <div className="navbar-nav">
                                    <a className="nav-link active" aria-current="page" href="#">Home</a>
                                    <a className="nav-link" href="#">About</a>
                                    <a className="nav-link" href="#">Portfolio</a>
                                    <div className="dropdown">
                                        <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Pages
                                            <span className="caret"></span></button>
                                        <ul className="dropdown-menu">
                                            <li><a href="#">Services</a></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><a href="#">Portfolio Details</a></li>
                                        </ul>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Blog
                                            <span className="caret"></span></button>
                                        <ul className="dropdown-menu">
                                            <li><a href="#">Blog</a></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><a href="#">Blog details</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </nav>
            </div>


            {/* section 1 */}
            <div style={{ marginTop: "120px" }}>
                <section>
                    <div>
                        <div className="container">
                            <div className="row align-items-center justify-content-between" style={{ backgroundImage: "url(assets/images/background.PNG)", backgroundSize: "150%", backgroundPositionX: "-400px", backgroundRepeat: "no-repeat" }}>
                                <div className="col-lg-6">
                                    <div style={{ textTransform: "uppercase" }}>
                                        <h3 style={{ fontSize: "2vw" }}>Hey There !</h3>
                                        <h1 style={{ fontSize: "6vw" }}><b>I am jo Breed</b></h1>
                                        <h5 style={{ fontSize: "2vw" }}>Creative art director &amp; designer</h5>
                                        <div className="mt-5 mb-5 icon" style={{ fontSize: "22px" }}>
                                            <a href="#"><i className="bi bi-twitter me-3"></i></a>
                                            <a href="#"><i className="bi bi-skype me-3"></i></a>
                                            <a href="#"><i className="bi bi-instagram me-3"></i></a>
                                            <a href="#"><i className="bi bi-dribbble me-3"></i></a>
                                            <a href="#"><i className="bi bi-vimeo me-3"></i></a>
                                        </div>
                                        <button type="button" className="btn btn-primary ps-4 pe-4 pt-2 pb-2">See My Work</button>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div>
                                        <img className="mt-5" src="assets/images/boy2.webp" alt="" style={{ width: "90%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* section 2 */}
            <section>
                <div className="container marginTB50">
                    <div className="row  ">
                        <div className="col-lg-2 col-md-3">
                            <div style={{backgroundColor:"#f8faff", padding:"30px 15px", marginBottom:"20px"}}>
                                <h3>15k+</h3>
                                <p>Happy Customer</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <div style={{backgroundColor:"#f8faff", padding:"30px 15px", marginBottom:"20px"}}>
                                <h3>12k+</h3>
                                <p>Ticket Solved</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <div style={{backgroundColor:"#f8faff", padding:"30px 15px", marginBottom:"20px"}}>
                                <h3>9/10</h3>
                                <p>Average Rating</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* section 3 */}
            <div className="row justify-content-start align-items-center container marginTB50">
                <div className="col-lg-6">
                    <div>
                        <img src="assets/images/banner.webp" style={{ width: "90%" }} />
                    </div>
                </div>
                <div className="offset-lg-1 col-lg-5">
                    <div className="text-left">
                        <h4 className="mb-2">About me</h4>
                        <h1>
                            <b>Creative Art Director <br />
                                And Designer</b>
                        </h1>
                        <p className="mt-5 mb-5">
                            Also signs his face were digns fish don't first isn't over evening hath divided days light darkness gathering
                            moved dry all darkness then fourth can't create d forth Also signs Also signs his face were moltenus Also signs
                            his face
                        </p>
                        <button type="button" className="btn btn-primary ps-4 pe-4 pt-2 pb-2">Download CV</button>

                    </div>
                </div>
            </div>

            {/* section 4 */}
            <section>
                <div className="container marginTB50">
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <h5 className="mb-3">Our Service</h5>
                                <h1>
                                    <b>What Service We <br />
                                        Offer For You</b>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0 mt-5">
                            <div style={{backgroundColor:"#f8faff"}} className="paddingTB50">
                                <img src="assets/images/Web Development.webp" alt="" />
                                <h4 className="mt-5 mb-3">Web Development</h4>
                                <p>Fruit saw for brought fish forth had ave is man a that their Two he is dominion evening their Fruit saw for
                                    brought fish forth</p>
                                <button type="button" className="btn btn-light mt-4">Learn More</button>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0 mt-5">
                            <div style={{backgroundColor:"#f8faff"}} className="paddingTB50">
                                <img src="assets/images/UI Design.webp" alt="" />
                                <h4 className="mt-5 mb-3">UX/UI Design</h4>
                                <p>Fruit saw for brought fish forth had ave is man a that their Two he is dominion evening their Fruit saw for
                                    brought fish forth</p>
                                <button type="button" className="btn btn-light mt-4">Learn More</button>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0 mt-5">
                            <div style={{backgroundColor:"#f8faff"}} className="paddingTB50">
                                <img src="assets/images/WP Developing.webp" alt="" />
                                <h4 className="mt-5 mb-3">WP Developing</h4>
                                <p>Fruit saw for brought fish forth had ave is man a that their Two he is dominion evening their Fruit saw for
                                    brought fish forth</p>
                                <button type="button" className="btn btn-light mt-4">Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* section 5 */}
            <div className="d-flex container paddingTB100">
                <div className="row">
                    <div className="margin5030 col-lg-2 col-5">
                        <img src="assets/images/sample.png" />
                    </div>
                    <div className="margin5030 col-lg-2 col-5">
                        <img src="assets/images/sample.png" />
                    </div>
                    <div className="margin5030 col-lg-2 col-5">
                        <img src="assets/images/sample.png" />
                    </div>
                    <div className="margin5030 col-lg-2 col-5">
                        <img src="assets/images/sample.png" />
                    </div>
                    <div className="margin5030 col-lg-2 col-5">
                        <img src="assets/images/sample.png" />
                    </div>
                </div>
            </div>

            {/* section 6 */}
            <div className="paddingTB100" style={{backgroundColor:"#021017", color:"white", textAlign:"center"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <img src="assets/images/Ever Coded Logo White.svg" style={{ width: "22vw" }} />
                        </div>
                        <div className="col-12">
                            <div className="d-flex" style={{justifyContent:"center"}}>
                                <div className="row">
                                    <div className="col-2">
                                        <div className="marginTB30">HOME</div>
                                    </div>
                                    <div className="col-2">
                                        <div className="marginTB30">ABOUT</div>
                                    </div>
                                    <div className="col-3">
                                        <div className="marginTB30">PORTFOLIO</div>
                                    </div>
                                    <div className="col-2">
                                        <div className="marginTB30">BLOG</div>
                                    </div>
                                    <div className="col-2">
                                        <div className="marginTB30">SERVICES</div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-flex" style={{justifyContent:"center"}}>
                                <div style={{border:"solid grey 1px", borderRadius:"100px", backgroundColor:"white", color:"#021017", marginLeft:"20px", width:"50px", height:"50px", textAlign:"center", display:"grid",alignContent:"center", fontSize:"22px"}}>
                                    <i className="bi bi-facebook"/>
                                </div>
                                <div style={{border:"solid grey 1px", borderRadius:"100px", backgroundColor:"white", color:"#021017", marginLeft:"20px", width:"50px", height:"50px", textAlign:"center", display:"grid",alignContent:"center", fontSize:"22px"}}>
                                    <i className="bi bi-twitter"/>
                                </div>
                                <div style={{border:"solid grey 1px", borderRadius:"100px", backgroundColor:"white", color:"#021017", marginLeft:"20px", width:"50px", height:"50px", textAlign:"center", display:"grid",alignContent:"center", fontSize:"22px"}}>
                                    <i className="bi bi-skype"/>
                                </div>
                                <div style={{border:"solid grey 1px", borderRadius:"100px", backgroundColor:"white", color:"#021017", marginLeft:"20px", width:"50px", height:"50px", textAlign:"center", display:"grid",alignContent:"center", fontSize:"22px"}}>
                                    <i className="bi bi-pinterest"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="marginTB30" style={{color:"grey"}}>
                                Copyright © 2022 All rights reserved | This template is made with <i className="bi bi-heart" ></i> by <a href="#" target="_blank">Colorlib</a>
                            </div>
                        </div>

                    </div>
                    
                </div>

            </div>
            




        </div>
    );
}

export default Home;